import type { Theme } from '@material-ui/core';
import { Box, Divider, Drawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { useDispatch } from 'react-redux';
import { hide } from 'src/store/slices/hideMenuSlice';
import BlackLogo from '../BlackLogo';
import CogIcon from '../../icons/Cog';
import useAuth from 'src/hooks/useAuth';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StorageIcon from '@material-ui/icons/Storage';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    linksWrapper: {
      '& .MuiButton-root': {
        fontWeight: 500,
      },
      '& .active': {
        color: '#c22027',
        fontWeight: 600,
        // backgroundColor: '#E6E6E6'
      },
    },
  })
);
const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const { user } = useAuth();
  const location = useLocation();
  const classes = useStyles();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const dispatch = useDispatch();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    const getId = location.pathname.split('/');
    if (
      location.pathname ===
      `/erp/crm/organization/details/${getId[getId.length - 1]}`
    ) {
      dispatch(hide(true));
    } else {
      dispatch(hide(false));
    }
  }, [location.pathname]);

  // const sections = [
  //   {
  //     title: 'Dashboard',
  //     viewable: true,
  //     items: [
  //       {
  //         title: 'Dashboard',
  //         path: '/erp/dashboard',
  //         viewable: true,
  //         icon: <DashboardIcon sx={{
  //           color: '#C22027!important'
  //         }} />,
  //       },
  //     ],
  //   },
  //   {
  //     title: 'OKR',
  //     viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //     items: [
  //       {
  //         title: 'OKR',
  //         path: '/erp/okr',
  //         viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //         icon: (
  //           <img
  //             alt="dashboard icon"
  //             src="/static/icons/dashboard-icon.png"
  //             width="14px"
  //             height="14px"
  //           />
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     title: 'CRM',
  //     viewable: (user?.permissions?.includes('sidebar-organization-read') || user?.permissions?.includes('sidebar-employee-read') || user?.permissions?.includes('sidebar-client-contact-read')),
  //     items: [
  //       {
  //         id: 'CRM-128320',
  //         path: "/crm",
  //         title: 'CRM',
  //         viewable: user?.permissions?.includes('sidebar-organization-read') || user?.permissions?.includes('sidebar-employee-read') || user?.permissions?.includes('sidebar-client-contact-read'),
  //         type: 'collapse',
  //         icon: (
  //           <img
  //             alt="employees icon"
  //             src="/static/icons/employees-icon.png"
  //             width="14px"
  //             height="14px"
  //           />
  //         ),
  //         children: [
  //           {
  //             title: 'Organization',
  //             path: '/erp/crm/organization',
  //             viewable: (user?.permissions?.includes('sidebar-organization-read')
  //               && (user?.permissions?.includes('organization-get-all')
  //                 || user?.permissions?.includes('organization-filter-tabs-read')
  //                 || user?.permissions?.includes('organization-create')
  //                 || user?.permissions?.includes('organization-toggle-status')
  //                 || user?.permissions?.includes('organization-delete')
  //                 || user?.permissions?.includes('organization-bulk-deactivate')))
  //           },
  //           {
  //             title: 'Employees',
  //             path: '/erp/crm/employees',
  //             viewable: (user?.permissions?.includes('sidebar-employee-read') && (
  //               user?.permissions?.includes('employee-get-all')
  //               || user?.permissions?.includes('employee-filter-tabs-read')
  //               || user?.permissions?.includes('employee-create')
  //               || user?.permissions?.includes('employee-toggle-status')
  //               || user?.permissions?.includes('employee-delete')
  //               || user?.permissions?.includes('employee-bulk-deactivate')
  //               || user?.permissions?.includes('employee-get-by-id')
  //               || user?.permissions?.includes('employee-update')))
  //           },
  //           {
  //             title: 'Contacts',
  //             path: '/erp/crm/contacts',
  //             viewable: user?.permissions?.includes('sidebar-client-contact-read') && (
  //               user?.permissions?.includes('client-contact-get-all')
  //               || user?.permissions?.includes('client-contact-filter-tabs-read')
  //               || user?.permissions?.includes('client-contact-create')
  //               || user?.permissions?.includes('client-contact-toggle-status')
  //               || user?.permissions?.includes('client-contact-delete')
  //               || user?.permissions?.includes('client-contact-update')
  //               || user?.permissions?.includes('client-contact-get-by-id')
  //               || user?.permissions?.includes('client-contact-bulk-deactivate'))
  //           },
  //         ]
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Cases',
  //     viewable: (user?.permissions?.includes('sidebar-case-read') && (
  //       user?.permissions?.includes('case-get-all')
  //       || user?.permissions?.includes('case-filter-tabs-read')
  //       || user?.permissions?.includes('case-filter-advanced-search-read')
  //       || user?.permissions?.includes('case-filter-autorefresh-read')
  //       || user?.permissions?.includes('case-create')
  //       || user?.permissions?.includes('case-toggle-status')
  //       || user?.permissions?.includes('case-delete')
  //       || user?.permissions?.includes('case-bulk-deactivate')
  //       || user?.permissions?.includes('case-get-by-id')
  //       || user?.permissions?.includes('case-update'))),
  //     items: [
  //       {
  //         title: 'Cases',
  //         path: '/erp/cases',
  //         viewable: (user?.permissions?.includes('sidebar-case-read') && (
  //           user?.permissions?.includes('case-get-all')
  //           || user?.permissions?.includes('case-filter-tabs-read')
  //           || user?.permissions?.includes('case-filter-advanced-search-read')
  //           || user?.permissions?.includes('case-filter-autorefresh-read')
  //           || user?.permissions?.includes('case-create')
  //           || user?.permissions?.includes('case-toggle-status')
  //           || user?.permissions?.includes('case-delete')
  //           || user?.permissions?.includes('case-bulk-deactivate')
  //           || user?.permissions?.includes('case-get-by-id')
  //           || user?.permissions?.includes('case-update'))),
  //         icon: (
  //           <img
  //             alt="cases icon"
  //             src="/static/icons/ticket_1.png"
  //             width="14px"
  //             height="14px"
  //           />
  //         ),
  //       }
  //     ],
  //   },
  //   {
  //     title: 'Internal Requests',
  //     viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //     items: [

  //       {
  //         title: 'Internal Requests',
  //         path: '/erp/internal-requests',
  //         viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //         icon: (
  //           <img
  //             alt="cases icon"
  //             src="/static/icons/report_icon.png"
  //             width="14px"
  //             height="14px"
  //           />
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     title: 'PIM',
  //     viewable: true,
  //     items: [

  //       {
  //         title: 'PIM',
  //         path: '/pim',
  //         viewable: true,
  //         type: 'collapse',
  //         icon: (
  //           <StorageIcon sx={{
  //             width: "14px",
  //             height: "14px",
  //             color: '#C22027!important'
  //           }} />
  //         ),
  //         children: [
  //           {
  //             title: 'Catalogues',
  //             path: '/pim/catalogues',
  //             viewable: true,
  //           },]
  //       },
  //     ],
  //   },
  //   {
  //     title: 'OMS',
  //     viewable: true,
  //     items: [

  //       {
  //         title: 'OMS',
  //         path: '/oms',
  //         viewable: true,
  //         type: 'collapse',
  //         icon: (
  //           <StorageIcon sx={{
  //             width: "14px",
  //             height: "14px",
  //             color: '#C22027!important'
  //           }} />
  //         ),
  //         children: [
  //           {
  //             title: 'Orders',
  //             path: '/oms/orders',
  //             viewable: true,
  //           },
  //           {
  //             title: 'Quotes',
  //             path: '/oms/quotes',
  //             viewable: true,
  //           },
  //         ]
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Settings',
  //     viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //     items: [
  //       {
  //         id: 'settings-128320',
  //         title: 'Settings',
  //         path: "/settings",
  //         viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //         type: 'collapse',
  //         icon: (<CogIcon color="secondary" />),
  //         children: [
  //           {
  //             title: 'Roles',
  //             path: '/erp/settings/roles',
  //             viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //           },
  //           {
  //             title: 'Permissions',
  //             path: '/erp/settings/permissions',
  //             viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //           },
  //           {
  //             title: 'Modules',
  //             path: '/erp/settings/modules',
  //             viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //           },
  //           {
  //             title: 'Status Flow',
  //             path: '/erp/settings/status-flow',
  //             viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //           },
  //           {
  //             title: 'ITSM Flow',
  //             path: '/erp/settings/itsm-flow',
  //             viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //           },
  //           {
  //             title: 'Billing Accounts',
  //             path: '/erp/settings/billing-accounts',
  //             viewable: user.role?.some((role) => role.identifier === "it22_admin"),
  //           },
  //         ]
  //       },
  //     ],
  //   },
  // ];

  const sections = [
    // {
    //   title: 'Dashboard',
    //   viewable: true,
    //   items: [
    //     {
    //       title: 'Dashboard',
    //       path: '/erp/dashboard',
    //       viewable: true,
    //       icon: (
    //         <DashboardIcon
    //           sx={{
    //             color: '#C22027!important',
    //           }}
    //         />
    //       ),
    //     },
    //   ],
    // },
    {
      title: 'Clients',
      viewable:
        user?.permissions?.includes('sidebar-organization-read') ||
        user?.permissions?.includes('sidebar-employee-read') ||
        user?.permissions?.includes('sidebar-client-contact-read'),
      items: [
        {
          id: 'CRM-128320',
          path: '/crm',
          title: 'Clients',
          viewable:
            user?.permissions?.includes('sidebar-organization-read') ||
            user?.permissions?.includes('sidebar-employee-read') ||
            user?.permissions?.includes('sidebar-client-contact-read'),
          type: 'collapse',
          icon: (
            <img
              alt="employees icon"
              src="/static/icons/employees-icon.png"
              width="14px"
              height="14px"
            />
          ),
          children: [
            {
              title: 'Companies',
              path: '/erp/crm/organization',
              viewable:
                user?.permissions?.includes('sidebar-organization-read') &&
                (user?.permissions?.includes('organization-get-all') ||
                  user?.permissions?.includes(
                    'organization-filter-tabs-read'
                  ) ||
                  user?.permissions?.includes('organization-create') ||
                  user?.permissions?.includes('organization-toggle-status') ||
                  user?.permissions?.includes('organization-delete') ||
                  user?.permissions?.includes('organization-bulk-deactivate')),
            },
            {
              title: 'Contacts',
              path: '/erp/crm/contacts',
              viewable:
                user?.permissions?.includes('sidebar-client-contact-read') &&
                (user?.permissions?.includes('client-contact-get-all') ||
                  user?.permissions?.includes(
                    'client-contact-filter-tabs-read'
                  ) ||
                  user?.permissions?.includes('client-contact-create') ||
                  user?.permissions?.includes('client-contact-toggle-status') ||
                  user?.permissions?.includes('client-contact-delete') ||
                  user?.permissions?.includes('client-contact-update') ||
                  user?.permissions?.includes('client-contact-get-by-id') ||
                  user?.permissions?.includes(
                    'client-contact-bulk-deactivate'
                  )),
            },
          ],
        },
      ],
    },
    {
      title: 'Orders',
      viewable: true,
      items: [
        {
          // id: 'CRM-128320',
          path: '/oms/orders',
          title: 'Orders',
          viewable:
            user?.permissions?.includes('sidebar-organization-read') ||
            user?.permissions?.includes('sidebar-employee-read') ||
            user?.permissions?.includes('sidebar-client-contact-read'),
          type: 'collapse',
          icon: (
            <StorageIcon
              sx={{
                width: '14px',
                height: '14px',
                color: '#C22027!important',
              }}
            />
          ),
          children: [
            {
              title: 'Overview',
              path: '/oms/orders',
              viewable: true,
            },
            {
              title: 'New Orders',
              path: '/oms/orders#create',
              viewable: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Quotes',
      viewable: true,
      items: [
        {
          // id: 'CRM-128320',
          path: '/oms/quotes',
          title: 'Quotes',
          viewable:
            user?.permissions?.includes('sidebar-organization-read') ||
            user?.permissions?.includes('sidebar-employee-read') ||
            user?.permissions?.includes('sidebar-client-contact-read'),
          type: 'collapse',
          icon: (
            <StorageIcon
              sx={{
                width: '14px',
                height: '14px',
                color: '#C22027!important',
              }}
            />
          ),
          children: [
            {
              title: 'Overview',
              path: '/oms/quotes',
              viewable: true,
            },
            {
              title: 'New Quotes',
              path: '/oms/quotes#create',
              viewable: true,
            },
          ],
        },
      ],
    },
    {
      title: 'ITSM',
      viewable:
        user?.permissions?.includes('sidebar-case-read') &&
        (user?.permissions?.includes('case-get-all') ||
          user?.permissions?.includes('case-filter-tabs-read') ||
          user?.permissions?.includes('case-filter-advanced-search-read') ||
          user?.permissions?.includes('case-filter-autorefresh-read') ||
          user?.permissions?.includes('case-create') ||
          user?.permissions?.includes('case-toggle-status') ||
          user?.permissions?.includes('case-delete') ||
          user?.permissions?.includes('case-bulk-deactivate') ||
          user?.permissions?.includes('case-get-by-id') ||
          user?.permissions?.includes('case-update')),
      items: [
        {
          title: 'ITSM',
          path: '/erp/cases',
          viewable:
            user?.permissions?.includes('sidebar-case-read') &&
            (user?.permissions?.includes('case-get-all') ||
              user?.permissions?.includes('case-filter-tabs-read') ||
              user?.permissions?.includes('case-filter-advanced-search-read') ||
              user?.permissions?.includes('case-filter-autorefresh-read') ||
              user?.permissions?.includes('case-create') ||
              user?.permissions?.includes('case-toggle-status') ||
              user?.permissions?.includes('case-delete') ||
              user?.permissions?.includes('case-bulk-deactivate') ||
              user?.permissions?.includes('case-get-by-id') ||
              user?.permissions?.includes('case-update')),
          type: 'collapse',
          icon: (
            <img
              alt="cases icon"
              src="/static/icons/ticket_1.png"
              width="14px"
              height="14px"
            />
          ),
          children: [
            {
              title: 'Dashboard',
              path: '/erp/dashboard',
              viewable:
                user?.permissions?.includes('sidebar-case-read') &&
                (user?.permissions?.includes('case-get-all') ||
                  user?.permissions?.includes('case-filter-tabs-read') ||
                  user?.permissions?.includes(
                    'case-filter-advanced-search-read'
                  ) ||
                  user?.permissions?.includes('case-filter-autorefresh-read') ||
                  user?.permissions?.includes('case-create') ||
                  user?.permissions?.includes('case-toggle-status') ||
                  user?.permissions?.includes('case-delete') ||
                  user?.permissions?.includes('case-bulk-deactivate') ||
                  user?.permissions?.includes('case-get-by-id') ||
                  user?.permissions?.includes('case-update')),
            },
            {
              title: 'Cases',
              path: '/erp/cases',
              viewable:
                user?.permissions?.includes('sidebar-case-read') &&
                (user?.permissions?.includes('case-get-all') ||
                  user?.permissions?.includes('case-filter-tabs-read') ||
                  user?.permissions?.includes(
                    'case-filter-advanced-search-read'
                  ) ||
                  user?.permissions?.includes('case-filter-autorefresh-read') ||
                  user?.permissions?.includes('case-create') ||
                  user?.permissions?.includes('case-toggle-status') ||
                  user?.permissions?.includes('case-delete') ||
                  user?.permissions?.includes('case-bulk-deactivate') ||
                  user?.permissions?.includes('case-get-by-id') ||
                  user?.permissions?.includes('case-update')),
            },
            {
              title: 'Reporting',
              path: '/erp/reporting',
              viewable: user?.permissions?.includes('sidebar-case-read'),
            },
            {
              title: 'Teams',
              path: '/erp/teams',
              viewable: user?.permissions?.includes('sidebar-case-read'),
            },
          ],
        },
      ],
    },
    {
      title: 'HR',
      // viewable: (user?.permissions?.includes('sidebar-organization-read') || user?.permissions?.includes('sidebar-employee-read') || user?.permissions?.includes('sidebar-client-contact-read')),
      viewable: true,
      items: [
        {
          // id: 'CRM-128320',
          title: 'HR',
          viewable:
            user?.permissions?.includes('sidebar-organization-read') ||
            user?.permissions?.includes('sidebar-employee-read') ||
            user?.permissions?.includes('sidebar-client-contact-read'),
          type: 'collapse',
          icon: (
            <StorageIcon
              sx={{
                width: '14px',
                height: '14px',
                color: '#C22027!important',
              }}
            />
          ),
          children: [
            {
              title: 'HR Cases',
              path: '/erp/internal-requests',
              viewable: user.role?.some(
                (role) => role.identifier === 'it22_admin'
              ),
            },
            {
              title: 'Employees',
              path: '/erp/crm/employees',
              viewable:
                user?.permissions?.includes('sidebar-employee-read') &&
                (user?.permissions?.includes('employee-get-all') ||
                  user?.permissions?.includes('employee-filter-tabs-read') ||
                  user?.permissions?.includes('employee-create') ||
                  user?.permissions?.includes('employee-toggle-status') ||
                  user?.permissions?.includes('employee-delete') ||
                  user?.permissions?.includes('employee-bulk-deactivate') ||
                  user?.permissions?.includes('employee-get-by-id') ||
                  user?.permissions?.includes('employee-update')),
            },
            {
              title: 'Leaves Quota',
              path: '/erp/leaves/quota',
              viewable: user.role?.some(
                (role) => role.identifier === 'it22_admin'
              ),
            },
            {
              title: 'Leaves Reporting',
              path: '/erp/leaves/reporting',
              viewable: user.role?.some(
                (role) => role.identifier === 'it22_admin'
              ),
            },
          ],
        },
      ],
    },
    {
      title: 'Products',
      viewable: true,
      items: [
        {
          path: '/pim/catalogues',
          title: 'Products',
          viewable:
            user?.permissions?.includes('sidebar-organization-read') ||
            user?.permissions?.includes('sidebar-employee-read') ||
            user?.permissions?.includes('sidebar-client-contact-read'),
          type: 'collapse',
          icon: (
            <StorageIcon
              sx={{
                width: '14px',
                height: '14px',
                color: '#C22027!important',
              }}
            />
          ),
          children: [
            {
              title: 'Overview',
              path: '/pim/catalogues',
              viewable: true,
            },
            {
              title: 'Catalogs',
              path: '/pim/catalogues',
              viewable: true,
            },
            {
              title: 'Import',
              // path: '/erp/crm/organization',
              viewable: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Settings',
      viewable: user.role?.some((role) => role.identifier === 'it22_admin'),
      items: [
        {
          title: 'Settings',
          viewable:
            user?.permissions?.includes('sidebar-organization-read') ||
            user?.permissions?.includes('sidebar-employee-read') ||
            user?.permissions?.includes('sidebar-client-contact-read'),
          type: 'collapse',
          icon: <CogIcon color="secondary" />,
          children: [
            {
              title: 'Permissions',
              viewable: user.role?.some(
                (role) => role.identifier === 'it22_admin'
              ),
              children: [
                {
                  title: 'Roles',
                  path: '/erp/settings/roles',
                  viewable: user.role?.some(
                    (role) => role.identifier === 'it22_admin'
                  ),
                },
                {
                  title: 'Permissions',
                  path: '/erp/settings/permissions',
                  viewable: user.role?.some(
                    (role) => role.identifier === 'it22_admin'
                  ),
                },
              ],
            },
            {
              title: 'Modules',
              path: '/erp/settings/modules',
              viewable: user.role?.some(
                (role) => role.identifier === 'it22_admin'
              ),
            },
            {
              title: 'ITSM',
              viewable: user.role?.some(
                (role) => role.identifier === 'it22_admin'
              ),
              children: [
                {
                  title: 'Status Flow',
                  path: '/erp/settings/status-flow',
                  viewable: user.role?.some(
                    (role) => role.identifier === 'it22_admin'
                  ),
                },
                {
                  title: 'ITSM Flow',
                  path: '/erp/settings/itsm-flow',
                  viewable: user.role?.some(
                    (role) => role.identifier === 'it22_admin'
                  ),
                },
                {
                  title: 'Declined Cases',
                  path: '/erp/cases/declined-cases',
                  viewable: user.role?.some(
                    (role) => role.identifier === 'it22_admin'
                  ),
                },
              ],
            },
            {
              title: 'Finances',
              viewable: user.role?.some(
                (role) => role.identifier === 'it22_admin'
              ),
              children: [
                {
                  title: 'Billing Accounts',
                  path: '/erp/settings/billing-accounts',
                  viewable: user.role?.some(
                    (role) => role.identifier === 'it22_admin'
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}>
          <RouterLink to="/">
            <BlackLogo height={40} width={40} />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }} className={classes.linksWrapper}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              viewable={section.viewable}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 200,
          },
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary">
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
