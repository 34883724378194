import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import TeamsTable from './teams-table';
import AddTeam from './add-team';
import { teamsApi } from 'src/api/teams';

const columns = [
  {
    id: 'name',
    label: 'Name',
    width: '8%',
    maxWidth: '8%',
    render: (row) => <Typography variant="body2">{row.name || '-'}</Typography>,
  },
  {
    id: 'feManager',
    label: 'FE Manager',
    width: '8%',
    maxWidth: '8%',
    render: (row) => (
      <Typography variant="body2">{row.feManager?.name || '-'}</Typography>
    ),
  },
  {
    id: 'feTeamLead',
    label: 'FE Team Lead',
    width: '8%',
    maxWidth: '8%',
    render: (row) => (
      <Typography variant="body2">{row.feTeamLead?.name || '-'}</Typography>
    ),
  },
  {
    id: 'beManager',
    label: 'BE Manager',
    width: '8%',
    maxWidth: '8%',
    render: (row) => (
      <Typography variant="body2">{row.beManager?.name || '-'}</Typography>
    ),
  },
  {
    id: 'beTeamLead',
    label: 'BE Team Lead',
    width: '8%',
    maxWidth: '8%',
    render: (row) => (
      <Typography variant="body2">{row.beTeamLead?.name || '-'}</Typography>
    ),
  },
  {
    id: 'projectManager',
    label: 'Project Manager',
    width: '8%',
    maxWidth: '8%',
    render: (row) => (
      <Typography variant="body2">{row.projectManager?.name || '-'}</Typography>
    ),
  },
  {
    id: 'members',
    label: 'Members',
    width: '8%',
    maxWidth: '8%',
    render: (row) =>
      row.members?.length > 0 ? (
        <Typography variant="body2">{row?.members?.length}</Typography>
      ) : (
        <Typography variant="body2">No members</Typography>
      ),
  },
];

const Teams = () => {
  const [teamsData, setTeamsData] = useState([]);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const addTeam = useCallback(
    async (open: boolean, event: any) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawer(open);
    },
    [drawer]
  );

  const handleCloseDrawer = () => {
    setDrawer(!drawer);
  };

  const fetchData = async () => {
    try {
      // Simulating an API call
      const response = await teamsApi.getAllTeams();
      setTeamsData(response?.data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography color="textPrimary" variant="h3">
            Teams
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => addTeam(true, e)}>
            Add Teams
          </Button>
        </Box>

        <TeamsTable
          columns={columns}
          rows={teamsData}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          totalPages={totalPages}
        />
      </Box>
      {drawer && (
        <AddTeam
          isOpen={drawer}
          addTeam={addTeam}
          closeDrawer={handleCloseDrawer}
          fetchData={fetchData}
        />
      )}
    </>
  );
};

export default Teams;
