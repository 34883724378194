import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';

const TeamsTable = ({
  columns,
  rows,
  page,
  setPage,
  pageSize,
  setPageSize,
  totalRecords,
}: any) => {

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          aria-label="customizable table"
          sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    width: column.width,
                    maxWidth: column.maxWidth,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    fontWeight: '700',
                  }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: '700' }}
                    color="textSecondary"
                    align="center">
                    No Data
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows?.map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  style={{ textDecoration: 'none', color: 'inherit' }}>
                  {columns?.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        width: column.width,
                        maxWidth: column.maxWidth,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                      }}>
                      {column.render ? column.render(row) : row[column.id] ?? '-'}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalRecords}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default TeamsTable;
