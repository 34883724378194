import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
} from '@mui/material';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { userApi } from 'src/api/userApi';
import { teamsApi } from 'src/api/teams';
import toast from 'react-hot-toast';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    createTeam: {
      width: '40%',
      padding: '5px 20px',
      zIndex: 1300,
      border: 'none',
      transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
      '& .ticketBody': {
        height: '90vh',
        overflowY: 'auto',
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        '& input': {
          borderRadius: '2px',
        },
      },
      '& h4': {
        backgroundColor: '#C22027',
        color: '#fff',
        padding: '0 40px',
        margin: '-5px -20px 30px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
      },

      '& select, & .MuiAutocomplete-inputRoot, & input': {
        height: '38px',
        padding: '0 10px',
        lineHeight: '116.7%',
        color: '#696969',
        width: '100%',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .ticketDropDown': {
        marginTop: '15px',
      },
    },
  })
);

const AddTeam = ({
  isOpen,
  addTeam,
  closeDrawer,
  fetchData,
}: {
  isOpen: boolean;
  closeDrawer: () => void;
  addTeam: (isFalse: boolean, e: any) => void;
  fetchData: any;
}) => {
  const classes = useStyles();
  const [employees, setEmployees] = useState<any>([]);

  const fetchEmployees = async () => {
    try {
      const response = await userApi.getUsersByOrganizationId(
        null,
        null,
        25000,
        null
      );
      setEmployees(response?.data?.data);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      feManager: null,
      feTeamLead: null,
      beManager: null,
      beTeamLead: null,
      projectManager: null,
    },
    onSubmit: async (values, actions) => {
      const extractIdAndName = (person) =>
        person ? { id: person.id, name: person.name } : null;

      const payload = {
        name: values.name, // Full name for the team
        feManager: extractIdAndName(values.feManager),
        feTeamLead: extractIdAndName(values.feTeamLead),
        beManager: extractIdAndName(values.beManager),
        beTeamLead: extractIdAndName(values.beTeamLead),
        projectManager: extractIdAndName(values.projectManager),
      };
      try {
        await teamsApi.addTeams(payload);
        toast.success('Team created successfully!');
        // Close the drawer after successful submission
        closeDrawer();
        // validate the all teams api
        fetchData();
        // Reset the form after successful submission
        actions.resetForm();
      } catch (error) {
        toast.error('Error creating team. Please try again.');
        actions.setSubmitting(false); // Ensure the submit button becomes clickable again
      }
    },
  });

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <div>
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={(e) => addTeam(false, e)}
        style={{ zIndex: 1300 }}
        classes={{ paper: classes.createTeam }}>
        <Typography variant="h4" component="h4">
          Create Teams
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="ticketBody">
            <div className="ticketDropDown">
              <TextField
                id="one-textarea"
                fullWidth
                size="small"
                name="name"
                placeholder="Name"
                value={formik?.values?.name}
                onChange={(e) => {
                  formik?.handleChange(e);
                }}
                error={Boolean(formik?.touched?.name && formik?.errors?.name)}
                helperText={formik?.touched?.name && formik?.errors?.name}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.feManager}
                onChange={(event, value) =>
                  formik.setFieldValue('feManager', value)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="FE Manager"
                    error={
                      formik.touched.feManager &&
                      Boolean(formik.errors.feManager)
                    }
                    helperText={
                      formik.touched.feManager && formik.errors.feManager
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.feTeamLead}
                onChange={(event, value) =>
                  formik.setFieldValue('feTeamLead', value)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="FE Team Lead"
                    error={
                      formik.touched.feTeamLead &&
                      Boolean(formik.errors.feTeamLead)
                    }
                    helperText={
                      formik.touched.feTeamLead && formik.errors.feTeamLead
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.beManager}
                onChange={(event, value) =>
                  formik.setFieldValue('beManager', value)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="FE Manager"
                    error={
                      formik.touched.beManager &&
                      Boolean(formik.errors.beManager)
                    }
                    helperText={
                      formik.touched.beManager && formik.errors.beManager
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.beTeamLead}
                onChange={(event, value) =>
                  formik.setFieldValue('beTeamLead', value)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="BE Team Lead"
                    error={
                      formik.touched.beTeamLead &&
                      Boolean(formik.errors.beTeamLead)
                    }
                    helperText={
                      formik.touched.beTeamLead && formik.errors.beTeamLead
                    }
                  />
                )}
              />
            </div>
            <div className="ticketDropDown">
              <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name || 'No name'}
                value={formik.values.projectManager}
                onChange={(event, value) =>
                  formik.setFieldValue('projectManager', value)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Project Manager"
                    error={
                      formik.touched.projectManager &&
                      Boolean(formik.errors.projectManager)
                    }
                    helperText={
                      formik.touched.projectManager &&
                      formik.errors.projectManager
                    }
                  />
                )}
              />
            </div>
            <Box display="flex" alignSelf="flex-end" mt="auto">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => closeDrawer()}
                sx={{ marginLeft: 'auto', height: '38px' }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ height: '38px', ml: 1 }}
                disabled={formik.isSubmitting}>
                Create
              </Button>
            </Box>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default AddTeam;
