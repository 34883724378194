import {
  Box,
  Card,
  CardContent,
  Grid,
  // LinearProgress,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import jwtDecode from 'jwt-decode';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../components/Logo';
import MobileLogo from '../../components/MobileLogo';
import { LoginJWT } from '../../components/authentication/login';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import { PasswordResetAmplify } from 'src/components/authentication/password-reset';

const useStyles = makeStyles<Theme>((theme: Theme) => createStyles({
  wrapper: {
    backgroundColor: 'background.default',
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    border: 0,
    borderRadius: 0,
    '& h3': {
      margin: '26px 0 40px',
    },
    '& h6': {
      width: '506px',
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
      paddingTop: '2.5rem',
    },
  },
  firstSectionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    background: 'url("/static/login_bg.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      background: 'none',
      minHeight: '20vh',
    },
  },
  card: {
    backgroundColor: 'transparent',
    border: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
      paddingTop: '4.5rem',
    },
  },
  desktopLogo: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileLogo: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  title: {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      color: 'black',
    },
  },
  subTitle: {
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  leftHeroWrapper: {
    display: 'flex',
  },
  leftHeroImage: {
    maxWidth: '40%',
    background: 'white',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  fullPageHero: {
    maxWidth: '100%',
  },
  imagesWrapper: {
    display: 'flex',
  },
  leftSideLogo: {
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    width: '50vw',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  textWhite: {
    color: 'white',
  },
  logo: {
    width: 150,
    marginBottom: 24,
  },
}));
const Login: FC = () => {
  const { isAuthenticated, isFirstTime } = useAuth() as any;
  const [isAuthenticatedLocal, setIsAuthenticatedLocal] = useState<boolean>(false);
  const classes = useStyles();
  const isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const currentTime = Date.now() / 1000;
    const decoded: any = jwtDecode(accessToken);
    return decoded.exp > currentTime;
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  useEffect(() => {
    if (isValidToken && localStorage.getItem('accessToken')?.length > 0) {
      setIsAuthenticatedLocal(isAuthenticated);
    } else if (!isValidToken) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.reload();
    } else {
      setIsAuthenticatedLocal(true);
    }
  }, [isAuthenticatedLocal]);

  return (
    <>
      <Helmet>
        <title>IT22 Support Admin | Login</title>
      </Helmet>
      <Box className={classes.wrapper}>
        {
       isAuthenticatedLocal && (
       <Grid container>
         <Grid
           item
           sm={12}
           md={6}
           sx={{
             width: '100%',
           }}
         >
           <Box className={classes.firstSectionWrapper}>
             <RouterLink
               to="/"
               className={classes.desktopLogo}
             >
               <Logo
                 height={112}
                 width={112}
               />
             </RouterLink>

             <RouterLink
               to="/"
               className={classes.mobileLogo}
             >
               <MobileLogo
                 height={112}
                 width={112}
               />
             </RouterLink>

             <Typography
               className={classes.title}
               color="body1"
               variant="h3"
             >
               Welcome To ITSM
             </Typography>
           </Box>
         </Grid>
         <Grid
           item
           sm={12}
           md={6}
           sx={{
             width: '100%',
           }}
         >
           <Card className={classes.card}>
             <CardContent
               sx={{
                 backgroundColor: 'transparent',
                 display: 'flex',
                 flexDirection: 'column',
                 p: 0,
               }}
             >
               <Box
                 sx={{
                   alignItems: 'center',
                   display: 'flex',
                   justifyContent: 'space-between',
                 }}
               >
                 <div>
                   <Typography
                     color="textPrimary"
                     gutterBottom
                     variant="h3"
                   >
                     Login into your account
                   </Typography>
                 </div>
               </Box>
               <Box
                 sx={{
                   flexGrow: 1,
                   mt: 3,
                 }}
               >
                 {
                      isFirstTime ? <PasswordResetAmplify /> : <LoginJWT />
                    }
               </Box>
             </CardContent>
           </Card>
         </Grid>
       </Grid>
       )

       }
      </Box>
    </>
  );
};

export default Login;
