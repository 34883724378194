/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { userApi } from 'src/api/userApi';
import { FormTypes } from 'src/types/components/form.type';
import logger from 'src/utils/logger';
import CustomButton from '../buttons/CustomButton';
import { isNaN, isUndefined } from 'lodash';
import PasswordField from './fields/PasswordField';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      textTransform: 'capitalize',
    },

    cardRoot: {
      margin: '27px 0 0px 0',
    },
    btnContainer: {
      paddingTop: '27px',
      paddingRight: '67px',
      paddingBottom: '58px',
      width: '100%',
      display: 'flex',
      gap: '10px',
      flexDirection: 'row',
      justifyContent: 'end',
    },
    loadingCircle: {
      color: theme.palette.primary.main,
      width: '40px !important',
      height: '40px !important',
      marginLeft: 5,
      marginRight: 5,
    },
    loadingWrapper: {
      //   marginTop: 20,
      textAlign: 'center',
    },
    imageUploadAvatar: {
      width: '50px',
      height: '50px',
    },
    imageUploadButton: {
      marginLeft: 10,
    },
  })
);

const Form: FC<FormTypes> = ({
  previewer,
  initialValues,
  schema,
  handleOnSubmit,
  returnUrl,
  showButtons,
}: FormTypes) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [defaultTimeZone, setdefaultTimeZone] = useState(
    initialValues?.timeZone
  );
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const handleCancel = () => {
    navigate(returnUrl || '/dashboard');
  };

  const handlePicture = async (e) => {
    try {
      setIsSubmitting(true);
      const fileType = e.target.files[0].type;
      if (!['image/jpeg', 'image/png'].includes(fileType)) {
        toast.dismiss();
        toast.error('Invalid File Type');
        setIsSubmitting(false);
        return null;
      }
      const image = await userApi.uploadFile(
        e.target.files[0],
        'user'
        // profileImage
      );
      // dispatch(userInfos(image?.fileName));
      setIsSubmitting(false);
      const msg = t('Image uploaded successfully');
      toast.dismiss();
      toast.success(msg, {
        duration: 10000,
      });
      return image;
    } catch (err) {
      setIsSubmitting(false);
      logger(err, 'error');
      const errorMsg =
        err?.response?.status === 400
          ? t('Image not uploaded file type error')
          : t('Image not uploaded file size error');
      toast.dismiss();
      toast.error(errorMsg, {
        duration: 10000,
      });
      return ''; // <--- cheapi
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100%',
      }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          handleOnSubmit(values);
        }}
        validationSchema={schema}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          dirty,
        }) => {
          console.log('initialValues', initialValues, values);

          return (
            <form noValidate>
              {previewer?.map((card) => (
                <Card variant="outlined" className={classes.cardRoot}>
                  <CardHeader
                    title={` ${card.name} `}
                    className={classes.header}
                  />
                  <Divider />
                  <Grid container spacing={3} sx={{ px: '24px', py: '35px' }}>
                    {card?.fields?.map((field: any) => {
                      {
                        if (field.type === 'dropDown') {
                          return (
                            <Grid item xs={field?.columnSize}>
                              {' '}
                              <FormControl disabled={field?.disabled} fullWidth>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  fullWidth
                                  value={defaultTimeZone}
                                  onChange={(e) => {
                                    setdefaultTimeZone(e.target.value);
                                    setFieldValue('timeZone', defaultTimeZone);
                                  }}
                                  name={field.name}>
                                  {field?.array?.map((arr) => (
                                    <MenuItem value={arr}>{arr}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          );
                        }
                        if (field.type === 'dropDownSimple') {
                          return (
                            <Grid item xs={field?.columnSize}>
                              {' '}
                              <FormControl
                                disabled={field?.disabled}
                                fullWidth
                                error={Boolean(
                                  errors[`${field.name}`] &&
                                    touched[`${field.name}`]
                                )}>
                                <InputLabel id="demo-simple-select-label">
                                  {field?.label}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id={field.id}
                                  fullWidth
                                  label={field?.label}
                                  // value={values?.organizationTypes}
                                  value={values[`${field.name}`]}
                                  onChange={handleChange}
                                  name={field?.name}>
                                  {field?.array?.length > 0 ? (
                                    field?.array?.map((arr) => (
                                      <MenuItem value={arr?.id}>
                                        {arr?.name}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem value="">{field.label}</MenuItem>
                                  )}
                                </Select>
                                <FormHelperText
                                  id={field.id}
                                  error={Boolean(
                                    errors[`${field.name}`] &&
                                      touched[`${field.name}`]
                                  )}>
                                  {touched[`${field.name}`] &&
                                    errors[`${field.name}`]}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          );
                        }
                        if (field.type === 'dropDownCountrySelect') {
                          return (
                            <Grid item xs={field?.columnSize}>
                              <Autocomplete
                                id="country-183320"
                                options={field.object || []}
                                value={values[`${field.name}`]}
                                onChange={(event: any, value: any) => {
                                  if (value === null) {
                                    setFieldValue('country', '');
                                    setFieldValue('city', '');
                                  } else {
                                    setFieldValue('country', value);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(
                                      errors[`${field.name}`] &&
                                        touched[`${field.name}`]
                                    )}
                                    helperText={
                                      touched[`${field.name}`] &&
                                      errors[`${field.name}`]
                                    }
                                    placeholder="Country"
                                  />
                                )}
                              />
                            </Grid>
                          );
                        }
                        if (field.type === 'dropDownCitySelect') {
                          return (
                            <Grid item xs={field?.columnSize}>
                              {' '}
                              <Autocomplete
                                id="city-183320"
                                options={field.object[values.country] || []}
                                value={values[`${field.name}`]}
                                onChange={(event: any, value: any) => {
                                  if (value === null) {
                                    setFieldValue('city', '');
                                  } else {
                                    setFieldValue('city', value);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(
                                      errors[`${field.name}`] &&
                                        touched[`${field.name}`]
                                    )}
                                    helperText={
                                      touched[`${field.name}`] &&
                                      errors[`${field.name}`]
                                    }
                                    InputLabelProps={{
                                      style: {
                                        color: 'inherit', // Color of the label when not focused
                                      },
                                    }}
                                    placeholder="City"
                                  />
                                )}
                              />
                            </Grid>
                          );
                        }
                        if (field.type === 'muiAutoComplete') {
                          return (
                            <Grid item xs={field?.columnSize}>
                              <Autocomplete
                                id="city-183320"
                                options={field?.array || []}
                                multiple
                                value={values[`${field.name}`]}
                                onChange={(event: any, value: any) => {
                                  if (value === null) {
                                    setFieldValue('roles', []);
                                  } else {
                                    setFieldValue('roles', value);
                                  }
                                }}
                                disabled={field?.disabled}
                                getOptionLabel={(option: any) => option?.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={field?.label || ''}
                                    error={Boolean(
                                      errors[`${field.name}`] &&
                                        touched[`${field.name}`]
                                    )}
                                    helperText={
                                      touched[`${field.name}`] &&
                                      errors[`${field.name}`]
                                    }
                                    InputLabelProps={{
                                      style: {
                                        color: 'inherit', // Color of the label when not focused
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          );
                        }
                        if (field.type === 'textarea') {
                          return (
                            <Grid item xs={12}>
                              <TextField
                                disabled={field?.disabled}
                                fullWidth
                                label={field.label}
                                name={field.name}
                                required={field.required}
                                value={values[`${field.name}`]}
                                variant="outlined"
                                multiline
                                rows={4}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors[`${field.name}`] &&
                                    touched[`${field.name}`]
                                )}
                                helperText={errors[`${field.name}`]}
                              />
                            </Grid>
                          );
                        }
                        if (field.type === 'file') {
                          return (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              sx={{ display: 'flex', alignItems: 'center' }}>
                              {isSubmitting ? (
                                <div className={classes.loadingWrapper}>
                                  <CircularProgress
                                    className={classes.loadingCircle}
                                  />
                                </div>
                              ) : (
                                <Avatar
                                  className={classes.imageUploadAvatar}
                                  src={
                                    values[field.name] == 'dummy_image.png'
                                      ? 'https://assets.it22.nl/fortes-energy/admin/dummy_image.png'
                                      : `${
                                          process.env.REACT_APP_IMAGE_UPLOAD_URL
                                        }/images/user/${values[field.name]}`
                                  }
                                />
                              )}
                              <TextField
                                className={classes.imageUploadButton}
                                name={field.name}
                                type={field.type}
                                onChange={async (e) => {
                                  setIsSubmitting(true);
                                  const image = await handlePicture(e);
                                  if (image) {
                                    setFieldValue('image', image?.fileName);
                                  }
                                  setIsSubmitting(false);
                                }}
                                inputProps={{
                                  accept: 'image/png, image/jpeg',
                                }}
                              />
                            </Grid>
                          );
                        }
                        if (field.type === 'password') {
                          return (
                            <Grid item xs={12} md={6}>
                              <PasswordField
                                field={field}
                                values={values}
                                errors={errors}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched}
                              />
                            </Grid>
                          );
                        }
                        return (
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label={field.label}
                              name={field.name}
                              required={field.required}
                              value={
                                field?.type === 'date' &&
                                values[`${field.name}`] &&
                                !isNaN(
                                  new Date(values[`${field.name}`]).getTime()
                                )
                                  ? new Date(values[`${field.name}`])
                                      .toISOString()
                                      .split('T')[0] // Only format for date fields2
                                  : values[`${field.name}`] // For non-date fields, use the raw value
                              }
                              type={field?.type}
                              variant="outlined"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors[`${field.name}`] &&
                                  touched[`${field.name}`]
                              )}
                              helperText={
                                touched[`${field.name}`] &&
                                errors[`${field.name}`]
                              }
                              InputLabelProps={field.InputLabelProps || {}}
                              disabled={field.disabled}
                            />
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Card>
              ))}

              <Box className={classes.btnContainer}>
                <CustomButton value="cancel" onClick={handleCancel} />
                {(isUndefined(showButtons) || showButtons) && (
                  <CustomButton
                    value="submit"
                    color="secondary"
                    disabled={!dirty}
                    onClick={handleSubmit}
                    type="submit"
                  />
                )}
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Form;
