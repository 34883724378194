import { Box, Container, Grid } from '@material-ui/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { organizationApi } from 'src/api/organizationApi';
import SimpleBreadCrumb from 'src/components/widgets/BreadCrumb/SimpleBreadCrumb';
import Form from 'src/components/widgets/forms/Form';
import setTitle from 'src/utils/setTitle';
import * as Yup from 'yup';
import useSettings from '../../../hooks/useSettings';
import gtm from '../../../lib/gtm';
import moment from 'moment-timezone';
import countriesAndCities from './countriesData';
import useAuth from 'src/hooks/useAuth';
import logger from 'src/utils/logger';

const AddOrganization: FC = () => {
  setTitle('CRM | Organizations - Add');
  const { user } = useAuth();
  const [organizationType, setOrganizationType] = useState<any>([]);
  const { settings } = useSettings();
  const navigate = useNavigate();
  const returnUrl = '/erp/crm/organization';

  const timeZones = moment.tz.names();
  const timeZoneCurrent = moment.tz.guess();

  const getAllOrganizationTypes = async () => {
    try {
      const response = await organizationApi.getOrganizationType();
      if (response?.status === 200) {
        setOrganizationType(response?.data?.data);
      }
      if (response?.status === 204) {
        setOrganizationType([]);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
        setOrganizationType([]);
      }
    }
  };

  logger(Object.keys(countriesAndCities), '', 'countries name: ');
  const previewData = [
    {
      name: 'organization details',
      fields: [
        {
          id: '1',
          name: 'organization_name',
          label: 'Organization Name',
          placeholder: 'Organization Name',
          type: 'text',
          required: true,
          fullWidth: true,
          columnSize: 6,
        },
        {
          id: '2',
          name: 'organizationTypes',
          label: 'Organization Type',
          placeholder: 'Organization Type',
          type: 'dropDownSimple',
          required: true,
          fullWidth: true,
          columnSize: 6,
          array: organizationType,
        },
        {
          id: '3',
          name: 'email',
          label: 'Email',
          placeholder: 'Email',
          type: 'text',
          required: true,
          fullWidth: true,
          columnSize: 6,
        },
        {
          id: '4',
          name: 'website',
          label: 'Website',
          placeholder: 'Website',
          type: 'text',
          required: true,
          fullWidth: true,
          columnSize: 6,
        },
        {
          id: '5',
          name: 'domainName',
          label: 'Domain Name',
          placeholder: 'Domain Name',
          type: 'text',
          required: true,
          fullWidth: true,
          columnSize: 6,
        },
        {
          id: '6',
          name: 'contactNo',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          type: 'number',
          required: true,
          fullWidth: true,
          columnSize: 6,
        },
        {
          id: '11',
          name: 'country',
          label: 'Country',
          placeholder: 'Country',
          type: 'dropDownCountrySelect',
          required: true,
          fullWidth: true,
          columnSize: 6,
          object: Object.keys(countriesAndCities)
        },
        {
          id: '9',
          name: 'city',
          label: 'City',
          placeholder: 'City',
          type: 'dropDownCitySelect',
          required: true,
          fullWidth: true,
          columnSize: 6,
          object: countriesAndCities
        },
        {
          id: '7',
          name: 'street',
          label: 'Street',
          placeholder: 'Street',
          type: 'text',
          required: true,
          fullWidth: true,
          columnSize: 6,
        },
        {
          id: '8',
          name: 'postal',
          label: 'Postal Code',
          placeholder: 'Postal Code',
          type: 'text',
          required: true,
          fullWidth: true,
          columnSize: 6,
        },
        {
          id: '10',
          name: 'province',
          label: 'Province',
          placeholder: 'Province',
          type: 'text',
          required: true,
          fullWidth: true,
          columnSize: 6,
        },

        {
          id: '12',
          name: 'timeZone',
          label: 'Timezone',
          placeholder: 'Timezone',
          type: 'dropDown',
          required: true,
          fullWidth: true,
          columnSize: 6,
          array: timeZones,
          timeZoneArea: timeZoneCurrent,
        },

        {
          id: '13',
          name: 'logo',
          label: 'Logo',
          placeholder: 'Logo',
          type: 'file',
          required: false,
          columnSize: 6,
          fullWidth: true,
        },

      ],
    },
    {
      name: 'Description',
      fields: [
        {
          id: '11',
          name: 'description',
          label: 'Description',
          placeholder: 'Description',
          type: 'textarea',
          required: false,
          fullWidth: true,
          columnSize: 12,
        },
      ],
    },
  ];

  const InitialValues = {
    organization_name: '',
    email: '',
    website: '',
    domainName: '',
    street: '',
    contactNo: 0,
    city: '',
    province: '',
    country: '',
    description: '',
    timeZone: timeZoneCurrent,
    organizationTypes: '',
    logo: 'string'
  };

  const Schema = Yup.object({
    organization_name: Yup.string()
      .required('Organization name required')
      .trim()
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    organizationTypes: Yup.string()
      .required('Organization type required'),
    email: Yup.string().email('Invalid email').required('Email required'),
    contactNo: Yup.string()
      .required('Phone number required')
      .trim()
      .matches(/^.{10,20}$/, 'Phone number must be between 10 and 20 characters in length'),
    website: Yup.string().url().required('Website required'),
    domainName: Yup.string().required('Domain name required').trim().test('Field must not contain only spaces', (value) => value && value.length > 0),
    street: Yup.string()
      .required('Street required')
      .trim()
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    postal: Yup.string().required('Postal code required').max(12, 'Maximum 12 characters allowed'),
    city: Yup.string()
      .trim()
      .required('City required'),
    province: Yup.string()
      .required('Province required')
      .trim()
      .test(
        'Field must not contain only spaces',
        (value) => value && value.length > 0
      ),
    country: Yup.string()
      .trim()
      .required('Country required'),
    description: Yup.string().required('Description required').max(2000, 'Maximum 2000 characters allowed'),
  });

  const handleOnSubmit = async (values: any) => {
    try {
      const filter = {
        city: values?.city,
        country: values?.country,
        description: values?.description,
        email: values?.email,
        isActivated: true,
        name: values?.organization_name,
        contactNo: [values?.contactNo],
        postal: values?.postal,
        province: values?.province,
        street: values?.street,
        website: values?.website,
        domainName: values?.domainName,
        timeZone: values?.timeZone,
        type: values?.organizationTypes,
        logo: 'string'
      };
      const response = await organizationApi.create(filter);
      if (response?.status === 201) {
        toast.success('Successfully Created');
        navigate(returnUrl);
      } else {
        toast.error('Internal Server Error');
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        if (error?.response?.data?.message) {
          error?.response?.data?.message?.map((msg) => toast.error(msg));
        }
      } else if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const BreadCrumbsData = {
    title: 'Add Organization',
    links: [
      {
        name: 'Organization',
        to: returnUrl,
      },
      {
        name: 'Add Organization',
        to: `${returnUrl}/new`,
      },
    ],
  };

  useEffect(() => {
    if (!user?.permissions?.includes('organization-create')) {
      window.location.href = '/admin/not-found';
    }
    gtm.push({ event: 'page_view' });
    getAllOrganizationTypes();
  }, []);

  return (
    <>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 8 }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <SimpleBreadCrumb data={BreadCrumbsData} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Form
              previewer={previewData}
              initialValues={InitialValues}
              schema={Schema}
              handleOnSubmit={handleOnSubmit}
              returnUrl={returnUrl}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AddOrganization;
