import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  // Typography,
} from '@material-ui/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import setTitle from 'src/utils/setTitle';
import gtm from '../../lib/gtm';
import DashboardTable from 'src/components/widgets/tables/tableDashboard';
import { dashboardApi } from 'src/api/dashboardApi';
import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { casesApi } from 'src/api/casesApi';
import { height, padding } from '@material-ui/system';
// import StackedBarChart from 'src/components/widgets/charts/StackedBarChart';
// import PieChart from 'src/components/widgets/charts/PieChart';

const Overview: FC = () => {
  setTitle('ERP | Dashboard');
  const navigate = useNavigate();
  const { user } = useAuth();
  const [caseType, setCaseType] = useState(1);
  const [casesData, setCasesData] = useState({
    open: 0,
    closed: 0,
    inProgress: 0,
  });
  const [totalData, setTotalData] = useState({
    totalOrganizations: 0,
    totalProjects: 0,
    activeUsers: 0,
  });
  const [recentCases, setRecentCases] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const smallScreen = useMediaQuery('(min-width:900px) and (max-width:1730px)');
  const useStyles = makeStyles<Theme>(() =>
    createStyles({
      root: {
        fontFamily: 'Quicksand',
        '& h1': {
          color: '#000',
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '116.7%',
          margin: 0,
        },
        '& p': {
          margin: 0,
        },
      },
      casesCard: {
        height: '277px',
        '& .content': {
          display: 'flex',
          '& .InnerLeft': {
            width: '40%',
            paddingTop: '30px',
          },
          '& .InnerRight': {
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
            '& .rightUpper': {
              display: 'flex',
              gap: '1.59vw',
              '& h1': {
                textAlign: 'center',
                color: '#50CD89',
                fontSize: '47px',
                fontWeight: 700,
              },
              '& p': {
                color: '#000',
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 600,
              },
            },
            '& .rightLower': {
              '& p': {
                color: '#000',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
              },
            },
          },
        },
      },
      formControl: {
        height: '30px',
        '& .MuiOutlinedInput-root': {
          height: '30px',
        },
        '& .MuiSelect-select': {
          padding: '3.5px 14px',
        },
      },
      recentCasesCard: {
        height: '551px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '4px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        '& h1': {
          padding: '8px 16px 10px',
        },
        '& .content': {
          flexGrow: 1,
          overflowY: 'auto',
        },
      },
      organizationsCard: {
        height: '258px',
        display: 'flex',
        flexDirection: 'column',
        '& .content': {
          display: 'flex',
          flexGrow: '1',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '60px',
          '& h1': {
            textAlign: 'center',
            color: '#C22027',
            fontSize: '47px',
            fontWeight: 700,
          },
          '& p': {
            color: '#000',
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 600,
          },
        },
      },
      upcomingMeetingsCard: {
        height: '258px',
        display: 'flex',
        flexDirection: 'column',
        '& .noOfMeetings': {
          color: '#C22027',
          fontSize: '47px',
          fontWeight: 700,
        },
        '& .content': {
          display: 'flex',
          flexGrow: '1',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'auto',
          '& .meetings': {
            '& .meetingDescription': {
              marginTop: '20px',
            },
          },
        },
      },
      upcomingEventsCard: {
        height: '258px',
        display: 'flex',
        flexDirection: 'column',
        '& .noOfEvents': {
          color: '#C22027',
          fontSize: '47px',
          fontWeight: 700,
        },
        '& .content': {
          display: 'flex',
          flexGrow: '1',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'auto',
          '& .events': {
            '& .eventsDescription': {
              marginTop: '20px',
            },
          },
        },
      },
      card: {
        borderRadius: '4px',
        background: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        padding: '20px 16px',
      },
      customBullet: {
        height: '5px',
        width: '10px',
        borderRadius: '10px',
        alignSelf: 'center',
        marginRight: '11px',
      },
    })
  );

  const classes = useStyles();

  const getCasesData = async () => {
    try {
      const response = await dashboardApi.getCasesData();
      if (response?.status === 200) {
        setCasesData({
          open: response?.data?.open,
          closed: response?.data?.closed,
          inProgress: response?.data?.inProgress,
        });
        setChartSeries([
          response?.data?.open,
          response?.data?.inProgress,
          response?.data?.closed,
        ]);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getRecentCases = async () => {
    try {
      const page = 0;
      const pageSize = 25;
      let queryParams = `page=${page}&pageSize=${pageSize}`;
      if (caseType === 0) {
        // created-by-me
        queryParams = `${queryParams}&createdBy=${user?.id}`;
      } else if (caseType === 2) {
        // assigned-to-me
        queryParams = `${queryParams}&assignee=${user?.id}`;
      }
      const res = await casesApi.advanceSearch(queryParams);
      if (res?.status === 200) {
        // setRecentCases(res?.data?.data.splice(5, 9));
        setRecentCases(res?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getTotalOrganizationsData = async () => {
    try {
      const response = await dashboardApi.getTotalOrganizationsData();
      if (response?.status === 200) {
        setTotalData({
          totalOrganizations: response?.data?.totalOrganizations,
          totalProjects: response?.data?.totalProjects,
          activeUsers: response?.data?.activeUsers,
        });
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: ['#50CD89', '#D9DADE', '#3E97FF'],
    dataLabels: {
      enabled: false,
    },
    labels: ['To do', 'In Progress', 'Closed'],
    legend: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: true,
            value: {
              show: true,
              offsetY: -10,
              color: '#000',
              // fontSize: '0.83vw',
              fontWeight: 500,
              fontFamily: 'Quicksand',
            },
            total: {
              show: true,
              label: '',
              color: '#000',
              // fontSize: '0.83vw',
              fontWeight: 500,
              fontFamily: 'Quicksand',
              showAlways: true,
              formatter(w) {
                const totalCases = w.globals.seriesTotals.reduce(
                  (a, b) => a + b,
                  0
                );
                return `${parseInt(totalCases.toFixed(1), 10)} cases`;
              },
            },
          },
        },
      },
    },
  };

  function formatNumberWithLeadingZero(number) {
    if (number === 0) {
      // Special case for zero
      return '0';
    }
    if (number < 10) {
      return `0${number.toString()}`;
    }
    return number.toString();
  }

  // const dataStackBarChart  = [
  //   {
  //     id: "To Do",
  //     name: "To Do",
  //     rank: 1,
  //     values: [5],
  //   },
  //   {
  //     id: "In Progress",
  //     name: "In Progress",
  //     rank: 3,
  //     values: [10],
  //   },
  //   {
  //     id: "Done",
  //     name: "Done",
  //     rank: 7,
  //     values: [20],
  //   },
  //   {
  //     id: "Waiting For Client",
  //     name: "Waiting For Client",
  //     rank: 4,
  //     values: [30],
  //   },
  //   {
  //     id: "Waiting For IT22",
  //     name: "Waiting For IT22",
  //     rank: 5,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Pending",
  //     name: "Pending",
  //     rank: 2,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Stale",
  //     name: "Stale",
  //     rank: null,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Re-Opened",
  //     name: "Re-Opened",
  //     rank: 8,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Resolution Planned",
  //     name: "Resolution Planned",
  //     rank: 6,
  //     values: [10, 20, 30],
  //   },
  //   {
  //     id: "Closed",
  //     name: "Closed",
  //     rank: 9,
  //     values: [10, 20, 30],
  //   },
  // ];
  // const dataPieChart = [
  //   { id: "gs", name: "General Support" },
  //   { id: "rs", name: "Redhat Support" },
  //   { id: "adm", name: "Administrative" },
  //   { id: "aps", name: "Application Support" },
  //   { id: "ab", name: "Application Bug" },
  //   { id: "afr", name: "Application Feature Request" },
  // ];
  useEffect(() => {
    gtm.push({ event: 'page_view' });
    getCasesData();
    getTotalOrganizationsData();
  }, []);

  useEffect(() => {
    getRecentCases();
  }, [caseType]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          px: 2,
          py: 4,
        }}>
        {/* <Typography variant="h2" sx={{ mb: 3 }}>
          {`Welcome, ${user?.name}`}
        </Typography> */}
        <Grid container spacing={2} className={classes.root}>
          {user?.permissions?.includes('dashboard-recent-cases-get-all') && (
            <Grid  item md={8}>
              <div className={`${classes.recentCasesCard}`}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  pr="20px">
                  <h1>Recent Cases</h1>
                  <FormControl className={classes.formControl}>
                    <Select
                      id="caseType-select"
                      value={caseType}
                      onChange={(e) => setCaseType(e.target.value)}
                      size="small"
                      sx={{ width: 'auto' }}>
                      <MenuItem value={1}>All</MenuItem>
                      <MenuItem value={0}>Created By Me</MenuItem>
                      <MenuItem value={2}>Assigned To Me</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <div className="content">
                  <DashboardTable recentCases={recentCases} />
                </div>
              </div>
            </Grid>
          )}
          <Grid container item spacing={2} md={4}>
            <Grid item md={12}>
              {user?.permissions?.includes('dashboard-cases-read') && (
                <div className={`${classes.casesCard} ${classes.card}`}>
                  <h1>Cases Overview</h1>
                  <div className="content">
                    <div className="InnerLeft">
                      {chartSeries?.length ? (
                        <Chart
                          height={smallScreen ? 170 : 200}
                          options={chartOptions}
                          series={chartSeries}
                          type="donut"
                        />
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          No Data Available{' '}
                        </div>
                      )}
                    </div>
                    <div className="InnerRight">
                      <div className="rightUpper">
                        <div>
                          <h1 style={{ color: '#50CD89' }}>
                            {formatNumberWithLeadingZero(casesData.open)}
                          </h1>
                          <p>To Do</p>
                        </div>
                        <div>
                          <h1 style={{ color: '#D9DADE' }}>
                            {formatNumberWithLeadingZero(casesData.inProgress)}
                          </h1>
                          <p>In Progress</p>
                        </div>
                        <div>
                          <h1 style={{ color: '#3E97FF' }}>
                            {formatNumberWithLeadingZero(casesData.closed)}
                          </h1>
                          <p>Closed</p>
                        </div>
                      </div>
                      <div className="rightLower">
                        <div style={{ display: 'flex' }}>
                          <div
                            className={classes.customBullet}
                            style={{ backgroundColor: '#50CD89' }}
                          />
                          <p>To Do</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div
                            className={classes.customBullet}
                            style={{ backgroundColor: '#D9DADE' }}
                          />
                          <p>In Progress</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div
                            className={classes.customBullet}
                            style={{ backgroundColor: '#3E97FF' }}
                          />
                          <p>Closed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item md={12}>
              {user?.permissions?.includes(
                'dashboard-organizations-counters-read'
              ) && (
                <div className={`${classes.organizationsCard} ${classes.card}`}>
                  <h1>Organizations</h1>
                  <div className="content">
                    <Box
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (
                          user?.permissions?.includes('organization-get-all') ||
                          user?.permissions?.includes(
                            'organization-filter-tabs-read'
                          ) ||
                          user?.permissions?.includes('organization-create') ||
                          user?.permissions?.includes(
                            'organization-toggle-status'
                          ) ||
                          user?.permissions?.includes('organization-delete') ||
                          user?.permissions?.includes(
                            'organization-bulk-deactivate'
                          )
                        ) {
                          navigate('/erp/crm/organization');
                        }
                      }}>
                      <h1>
                        {formatNumberWithLeadingZero(
                          totalData.totalOrganizations
                        )}
                      </h1>
                      <p>Total Organizations</p>
                    </Box>
                    <Box>
                      <h1>
                        {formatNumberWithLeadingZero(totalData.totalProjects)}
                      </h1>
                      <p>Total Projects</p>
                    </Box>
                    <Box>
                      <h1>
                        {formatNumberWithLeadingZero(totalData.activeUsers)}
                      </h1>
                      <p>Active Users</p>
                    </Box>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>

          {/* {(user?.permissions?.includes('dashboard-upcoming-meetings-counters-read') || user?.permissions?.includes('dashboard-upcoming-events-counters-read')) && (
          <Grid
            item
            container
            spacing={2}
            md={8}
          > */}
          {/* {user?.permissions?.includes('dashboard-upcoming-meetings-counters-read') && (
            <Grid
              item
              md={6}
            >
              <div
                className={`${classes.upcomingMeetingsCard} ${classes.card}`}
              >
                <h1>Upcoming Meetings</h1>
                <h1 className="noOfMeetings">{formatNumberWithLeadingZero(numberOfUpcMeetings)}</h1>
                <div className="content">
                  <div className="meetings">
                    {upcomingMeetings?.map((meeting) => (
                      <div className="meetingDescription">
                        <p style={{ fontWeight: 'bold' }}>{meeting?.title}</p>
                        <p>{`${meeting?.description?.replace(/<[^>]*>|&nbsp;/g, '').slice(0, 100)} ${meeting?.description?.length > 100 ? '...' : ''}`}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Grid>
            )} */}
          {/* {user?.permissions?.includes('dashboard-upcoming-events-counters-read') && (
              <Grid
                item
                md={6}
              >
                <div
                  className={`${classes.upcomingEventsCard} ${classes.card}`}
                >
                  <h1>Upcoming Events</h1>
                  <h1 className="noOfEvents">{formatNumberWithLeadingZero(numberOfUpcEvents)}</h1>
                  <div className="content">
                    <div className="events">
                      {upcomingEvents?.map((event) => (
                        <div className="eventsDescription">
                          <p style={{ fontWeight: 'bold' }}>{event?.title}</p>
                          <p>{`${event?.description?.replace(/<[^>]*>|&nbsp;/g, '').slice(0, 100)} ${event?.description?.length > 100 ? '...' : ''}`}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Grid>
              )} */}

          {/* </Grid>
          )} */}
          {/* <Grid
                item
                md={12}
              ><div className={` ${classes.card}`}>
               <StackedBarChart data={dataStackBarChart} width={1200} height={420}  title="Statuses"/>
               </div>
              </Grid>
              <Grid
                item
                md={12}
              ><div className={` ${classes.card}`}>
                 <h1>Case Types</h1>
               <PieChart data={dataPieChart} width={600} height={420} />
               </div>
              </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default Overview;
